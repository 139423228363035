/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import styled from "styled-components";
import _, { debounce } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";

import Input from "../Input";
import FieldError from "../FieldError";
import {
  cleanSkills as cleanProjects,
  ENDPOINT_INVOICES,
  INVOICE_TYPES,
  INVOICE_TYPE_COMMITMENT,
  INVOICE_TYPE_CREDIT_NOTE,
  INVOICE_TYPE_FINAL,
  INVOICE_TYPE_PURCHASE,
  INVOICE_TYPE_SALE,
} from "../../utils/api";
import { SEARCH_INITIAL_LIST } from "../../configs/constants/global";
import { StyledDateTimePicker, StyledForm } from "../../utils/styles";
import UserSelector from "../UserSelector";
import Icon from "../Icon";
import useDidUpdate from "../../hooks/useDidUpdate";
import usePrevious from "../../hooks/usePrevious";
import ProjectSelector from "../ProjectSelector";
import { isAdmin } from "../../utils/auth";
import Select from "../Select";
import SearchSelector from "../SearchSelector";

const InvoiceForm = ({ id, invoice, payouts, proceed, errors }) => {
  const firstCall = useRef(false);

  const { project, projects } = useSelector(({ Projects }) => Projects);

  const [state, setState] = useState({
    invoice: invoice || {},
    payouts: payouts || { 0: {} },
    project: { ...(project && Object.keys(project).length !== 0 ? project : invoice.project) },
    projectTitle: invoice?.projectTitle || "",
    error: null,
    errors: errors || null,
    addTeamMember: false,
  });

  const [invoices, setInvoices] = useState([]);
  const [commitmentPayment, setCommitmentPayment] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);

  const currency =
    (state?.invoice?.currency || state?.project?.currency) &&
    `(in ${state?.invoice?.currency || state?.project?.currency || "EUR"})`;

  const prevState = usePrevious(state);

  const fetchProjectCommitment = async (projectId, search) => {
    setLoading(true);
    const response = await axios.get(ENDPOINT_INVOICES, {
      params: {
        types: INVOICE_TYPE_COMMITMENT,
        project: projectId,
        show_all: true,
        search,
      },
    });

    firstCall.current = true;
    if (!firstCall.current) {
      localStorage.setItem(SEARCH_INITIAL_LIST, JSON.stringify(response?.data?.results || []));
    }

    setCommitmentPayment(response?.data?.results || []);
    setLoading(false);
  };

  const fetchInvoices = async (projectId, search) => {
    setLoading(true);

    const response = await axios.get(ENDPOINT_INVOICES, {
      params: {
        types: INVOICE_TYPE_SALE,
        project: +projectId,
        show_all: true,
        search,
      },
    });

    firstCall.current = true;

    if (!firstCall.current) {
      localStorage.setItem(SEARCH_INITIAL_LIST, JSON.stringify(response?.data?.results || []));
    }

    setInvoices(response.data?.results);
    setLoading(false);
  };

  const getSearchedItems = useMemo(() => {
    return debounce((projectId, search) => {
      fetchProjectCommitment(projectId, search);
    }, 500);
  }, []);

  const getSearchedInvoiceItems = useMemo(() => {
    return debounce((projectId, search) => {
      fetchInvoices(projectId, search);
    }, 500);
  }, []);

  const onChangeValue = (key, value) => {
    setState((prev) => ({
      ...prev,
      invoice: { ...prev.invoice, [key]: value },
      ...(key === "project" ? { [key]: value } : {}),
      ...(key === "projectTitle" ? { [key]: value } : {}),
      errors: { ...prev.errors, [key]: null },
    }));
  };

  const onPayoutUpdate = (idx, key, value) => {
    const newPayout = {};
    const newState = {};
    if (!value && key === "user") {
      return;
    }

    newPayout[key] = value;
    newState[idx] = {
      ...(state.payouts[idx] || {}),
      ...newPayout,
    };

    setState({ ...state, payouts: { ...state.payouts, ...newState } });
  };

  const checkRequired = (payout) => {
    if (Object.keys(state.payouts).length === 1) {
      return true;
    }

    if (Object.keys(payout).length > 0 && payout.user) {
      return true;
    }
    return false;
  };

  const onChangeField = (e, key) => onChangeValue(key, e.target.value);

  const checkDisplayAddTeamButton = (payOuts) => {
    let teamAddition = true;
    Object.entries(payOuts).forEach(([, value]) => {
      if (
        !(
          Object.hasOwnProperty.call(value, "user") &&
          Object.hasOwnProperty.call(value, "amount") &&
          value.amount
        )
      ) {
        teamAddition = false;
      }
    });
    if (teamAddition) {
      setState({ ...state, addTeamMember: true });
    } else {
      setState({ ...state, addTeamMember: false });
    }
  };

  const userRemoved = (user) => {
    let deleteKey = null;

    const { payouts: payOuts } = state;
    Object.entries(payOuts).forEach(([key, value]) => {
      if (value.user && value.user.id === user) {
        deleteKey = key;
      }
    });

    if (deleteKey && Object.keys(payOuts).length > 1) {
      delete payOuts[deleteKey];
    } else {
      payOuts[0] = {};
    }

    checkDisplayAddTeamButton(payOuts);

    setState({ ...state, payouts: { ...state.payouts, ...payouts } });
  };

  const renderPayOut = (idx) => {
    const payout = state.payouts[idx] || {};

    return (
      <StyledRow key={idx}>
        <Col sm="7">
          {idx === 0 && (
            <label htmlFor="team-member">
              Team Member
              <span style={{ color: "#da3451", paddingLeft: "2px" }}>*</span>
            </label>
          )}

          <UserSelector
            users={project?.participants || state.project?.participants}
            filter={{}}
            label=""
            type="singular"
            max={1}
            className="py-0"
            variant="bottom"
            placeholder="Select from project"
            selected={payout.user ? [payout.user] : []}
            onChange={(users) => onPayoutUpdate(idx, "user", users[0])}
            userRemoved={(user) => userRemoved(user)}
          />
        </Col>

        <Col sm="5">
          <FormGroup>
            {idx === 0 && (
              <label htmlFor="amount">
                Amount (in EUR)
                <span
                  style={{
                    color: "#da3451",
                    paddingLeft: "2px",
                  }}
                >
                  *
                </span>
              </label>
            )}

            <Input
              type="number"
              value={payout.amount || ""}
              className="py-0"
              dataTestId="amount"
              onChange={(e) => e.target.value >= 0 && onPayoutUpdate(idx, "amount", e.target.value)}
              placeholder="Enter amount"
              required={checkRequired(payout)}
            />
          </FormGroup>
        </Col>
      </StyledRow>
    );
  };

  const onSave = (e) => {
    e.preventDefault();

    if (!(project?.id || invoice?.id) && _.isEmpty(state.project)) {
      setState({
        ...state,
        errors: { project_title_error: "Select a project" },
      });

      return false;
    }

    let errorRequiredFields = false;
    let message = null;
    Object.entries(state.payouts).forEach(([, value]) => {
      if (!value.user && value.amount) {
        errorRequiredFields = true;
        message = "Select team member";
      }
    });

    if (errorRequiredFields) {
      setState({
        ...state,
        error: { message, section: "team" },
      });
    } else if (proceed) {
      if (invoice.type === INVOICE_TYPE_PURCHASE) {
        proceed({
          invoice: state.invoice,
          payouts: state.payouts,
          project: state.project,
        });
      } else {
        if (
          (invoice.type === INVOICE_TYPE_FINAL || invoice.type === INVOICE_TYPE_CREDIT_NOTE) &&
          state?.invoice?.ref_invoice?.id
        ) {
          state.invoice.ref_invoice = {
            id: state?.invoice?.ref_invoice?.id,
          };
        } else {
          delete state.invoice.ref_invoice;
        }

        proceed(state.invoice);
      }
    }
    return true;
  };

  const onAddPayout = () => {
    const { payouts: payOuts } = state;

    let idx = null;
    const newState = {};
    if (Object.keys(payOuts).length > 0) {
      idx = parseInt(Object.keys(payOuts)[Object.keys(payOuts).length - 1], 10) + 1;
    } else {
      idx = Object.keys(payOuts).length;
    }
    newState[idx] = {};

    let allowPayoutCreation = true;
    if (idx === 1) {
      const firstPayout = payOuts[Object.keys(payOuts)[0]];
      if (
        !(
          Object.hasOwnProperty.call(firstPayout, "user") &&
          Object.hasOwnProperty.call(firstPayout, "amount")
        )
      ) {
        allowPayoutCreation = false;
      }
    }
    if (allowPayoutCreation) {
      setState({ ...state, payouts: { ...state.payouts, ...newState } });
    }
  };

  useDidUpdate(() => {
    const { payouts: payOuts } = state;
    if (!_.isEqual(payOuts, prevState.payouts)) {
      checkDisplayAddTeamButton(payOuts);
    }
  }, [state.payouts]);

  useEffect(() => {
    if (project?.id || invoice?.id) {
      if (state.invoice.type === INVOICE_TYPE_FINAL) {
        fetchProjectCommitment(project?.id || invoice?.id).then(() => {
          setShowSuggestions(true);
        });
      } else if (state.invoice.type === INVOICE_TYPE_CREDIT_NOTE) {
        fetchInvoices(project?.id || invoice?.id).then(() => {
          setShowSuggestions(true);
        });
      }
    }
  }, [state.invoice.type, project?.id, invoice?.id]);

  useEffect(() => {
    if (errors && Object.keys(errors).length) setState((prev) => ({ ...prev, errors }));
  }, [errors]);

  const filterProjects = (category) => {
    const filteredProjects = cleanProjects(projects).filter((proj) => proj.type === category);
    return filteredProjects;
  };

  let commitment_amount =
    state.invoice?.ref_invoice?.amount ??
    state.invoice?.project?.commitment_amount_balance ??
    state.invoice.commitment_amount ??
    "";

  return (
    <StyledForm id={id} data-testid="invoice-form" onSubmit={onSave}>
      {/* Project not pre-selected means comes from dashboard */}
      {!(project?.id || invoice?.id) && (
        <FormGroup data-testid="projectInput">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            Project
            <LabelStyle>
              <span className="label-style">*</span>
            </LabelStyle>
          </label>
          {state.errors && state.errors.project_title_error ? (
            <FieldError message={state.errors.project_title_error} />
          ) : null}
          <ProjectSelector
            onChange={(title) => onChangeValue("projectTitle", title)}
            handleProjectObj={(proj) => {
              onChangeValue("project", proj);
              commitment_amount = proj?.commitment_amount_balance;

              // reset ref Invoice
              setState((prev) => ({
                ...prev,
                invoice: {
                  ...prev.invoice,
                  ref_invoice: {},
                },
              }));

              if (state.invoice.type === INVOICE_TYPE_CREDIT_NOTE) {
                fetchInvoices(proj.id).then(() => {
                  setShowSuggestions(true);
                });
              }

              if (state.invoice.type === INVOICE_TYPE_FINAL) {
                fetchProjectCommitment(proj.id, "").then(() => {
                  setShowSuggestions(true);
                });
              }
            }}
            value={state.projectTitle}
            selected={filterProjects("project") || []}
            placeholder="Choose project"
            queryParams={{
              stage: "active",
              archived: "False",
              show_all: isAdmin(),
            }}
          />
        </FormGroup>
      )}

      {state.invoice.type === INVOICE_TYPE_FINAL && (
        <FormGroup>
          <label
            htmlFor="commitment_payment"
            data-testid="commitment_payment"
            className="control-label"
          >
            Commitment Payment
          </label>
          {state.errors && state.errors.ref_invoice && (
            <FieldError message={state.errors.ref_invoice} />
          )}
          <SearchSelector
            loading={loading}
            showSuggestions={showSuggestions}
            handleSelectObj={(obj) => {
              onChangeValue("ref_invoice", obj);
              onChangeValue("commitment_amount", +obj.amount);

              setShowSuggestions(false);
            }}
            itemList={commitmentPayment || []}
            actionDispatch={(search) => {
              if (search === "") {
                onChangeValue("ref_invoice", {});
              } else {
                getSearchedItems(state?.project?.id || state?.invoice?.id, search);
              }
            }}
            disabled={!(state?.invoice?.project?.id || state?.project?.id)}
            value={state.invoice?.ref_invoice?.title || ""}
            selected={state.invoice?.ref_invoice?.title || ""}
            placeholder="Select/search commitment payment"
            queryParams={{}}
            setItemList={setCommitmentPayment}
          />
        </FormGroup>
      )}

      <FormGroup>
        <label>
          {INVOICE_TYPES[invoice?.type]} Title
          <LabelStyle>
            <span className="label-style">*</span>
          </LabelStyle>
        </label>

        {state.errors && state.errors.title && <FieldError message={state.errors.title} />}
        <Input
          value={state.invoice.title}
          onChange={(e) => onChangeField(e, "title")}
          placeholder="Enter a title for this payment"
          dataTestId="paymentTitle"
          required
        />
        <div className="text text-sm">Don&#39;t include project title here</div>
      </FormGroup>

      {state.invoice.type === INVOICE_TYPE_CREDIT_NOTE && (
        <FormGroup>
          <label htmlFor="ref_invoices" data-testid="ref_invoices" className="control-label">
            Reference Invoice
          </label>
          {state.errors && state.errors.ref_invoice && (
            <FieldError message={state.errors.ref_invoice} />
          )}
          <SearchSelector
            loading={loading}
            showSuggestions={showSuggestions}
            handleSelectObj={(obj) => {
              onChangeValue("ref_invoice", obj);
              setShowSuggestions(false);
            }}
            itemList={invoices || []}
            actionDispatch={(search) => {
              if (search === "") {
                onChangeValue("ref_invoice", {});
              } else {
                getSearchedInvoiceItems(state?.project?.id || state?.invoice?.id, search);
              }
            }}
            disabled={!state?.project?.id}
            value={state.invoice?.ref_invoice?.title || ""}
            selected={state.invoice?.ref_invoice?.title || ""}
            placeholder="Select/search invoices"
            queryParams={{}}
            setItemList={setInvoices}
          />
        </FormGroup>
      )}

      <FormGroup>
        <label htmlFor="invoice-date">Invoice Date</label>
        {state.errors && state.errors.issued_at && <FieldError message={state.errors.issued_at} />}
        <StyledDateTimePicker
          $calendar
          id="invoice-date"
          className="tg-date-field"
          placeholder="Enter invoice date"
          format="DD MMM YYYY"
          $time={false}
          value={state.invoice.issued_at ? new Date(state.invoice.issued_at) : null}
          onChange={(issued_at) => {
            onChangeValue("issued_at", moment.utc(issued_at).format());
          }}
          required
        />
      </FormGroup>
      {[
        INVOICE_TYPE_SALE,
        INVOICE_TYPE_CREDIT_NOTE,
        INVOICE_TYPE_COMMITMENT,
        INVOICE_TYPE_FINAL,
      ].includes(state.invoice.type) ? (
        <>
          <FormGroup>
            <label htmlFor="amount">
              Amount {currency}
              <LabelStyle>
                <span className="label-style">*</span>
              </LabelStyle>
            </label>
            {state.errors && state.errors.amount && <FieldError message={state.errors.amount} />}
            <Input
              id="amount"
              type="number"
              dataTestId="amountInEur"
              value={state.invoice.amount || ""}
              onChange={(e) => e.target.value >= 0 && onChangeValue("amount", e.target.value)}
              step="0.01"
              placeholder="Enter amount"
              required
            />
          </FormGroup>

          {state.invoice.type === INVOICE_TYPE_FINAL && (
            <FormGroup>
              <label htmlFor="commitment-amount">
                Commitment Amount {currency}
                <LabelStyle>
                  <span className="label-style">*</span>
                </LabelStyle>
              </label>
              {state.errors && state.errors.commitment_amount && (
                <FieldError message={state.errors.commitment_amount} />
              )}
              <Input
                id="commitment-amount"
                type="number"
                value={commitment_amount}
                onChange={(e) =>
                  e.target.value >= 0 && onChangeValue("commitment_amount", e.target.value)
                }
                step="0.01"
                placeholder="Enter commitment amount"
                required
              />
            </FormGroup>
          )}

          {state.invoice.type !== INVOICE_TYPE_CREDIT_NOTE && (
            <FormGroup>
              <label htmlFor="payment-period" className="control-label">
                Payment Period (in days)
                <LabelStyle>
                  <span className="label-style">*</span>
                </LabelStyle>
              </label>
              {state.errors && state.errors.payment_period && (
                <FieldError message={state.errors.payment_period} />
              )}
              <Select
                id="payment-period"
                onChange={(e) => onChangeField(e, "payment_period")}
                value={state.invoice.payment_period ? state.invoice.payment_period : "14"}
                required
              >
                <option value="7">7</option>
                <option value="14">14</option>
                <option value="21">21</option>
                <option value="30">30</option>
                <option value="60">60</option>
              </Select>
            </FormGroup>
          )}
        </>
      ) : (
        <div>
          {state.error && state.error.section === "team" && (
            <FieldError message={state.error.message} />
          )}

          {Object.keys(state.payouts).map((idx) => {
            return renderPayOut(+idx);
          })}

          {state.addTeamMember && (
            <div className="add-more">
              <button type="button" onClick={onAddPayout}>
                <Icon name="round-add" size="main" /> Add Another Team Member
              </button>
            </div>
          )}
        </div>
      )}
    </StyledForm>
  );
};

const StyledRow = styled(Row)`
  .item.nolabel {
    padding: 4px 8px;
    button {
      height: unset;
      line-height: unset;
      font-size: 0.2rem !important;
      margin-top: 4px;
    }
  }
`;

const LabelStyle = styled.span`
  .label-style {
    color: #da3451;
    padding-left: 2px;
  }
`;

export default InvoiceForm;
