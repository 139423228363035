/**
 * This get called before an async function is executed
 * @param {string} type
 * @returns
 */
export function start(type) {
  return { type };
}

/**
 * This dispatches successful Promises
 * @param {string} type
 * @param {*} data
 * @returns
 */
export function success(type, data) {
  return { type, data };
}

/**
 * For dispatching failed Promises
 * @param {string} type
 * @param {object} param1
 * @returns
 */
export function failed(type, { message }) {
  const error = message;
  return { type, error };
}

/**
 * For formatting error
 * @param {Error} error
 * @returns
 */
export function formatErrorResponse(error) {
  let msg = "Sorry, An error occurred.";

  if (error.response && error.response.data && typeof error.response.data !== "string") {
    const { data } = error.response;

    if (Array.isArray(data) && typeof data[0] !== "string") {
      const errorMessages = [];

      data.forEach((errorObj) => {
        Object.keys(errorObj).forEach((key) => {
          errorMessages.push(...errorObj[key]);
        });
      });
      msg = errorMessages.join(" ");
      return { message: msg };
    }

    Object.keys(data).map((key) => {
      if (key !== "message") {
        msg = typeof data[key] === "object" ? data[key][0] : data[key];
      } else {
        msg = data[key];
      }

      return msg;
    });
  }

  return { message: msg };
}
