import React, { useEffect, useState } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  APPROVE_ACTION,
  APPROVE_BATCH_ACTION,
  APPROVE_CSO_PAYOUT_ACTION,
  ARCHIVE_ACTION,
  DELETE_ACTION,
  DELETE_ACTION_BATCH,
  EDIT_ACTION,
  EDIT_ACTION_BATCH,
  GENERATE_INVOICE_ACTION,
  MARK_AS_PAID_ACTION,
  MARK_AS_PAID_BATCH_ACTION,
  REJECT_ACTION,
  REJECT_PAYOUT_ACTION,
  SEND_FOR_APPROVAL_ACTION,
  SEND_REMINDER,
  UNARCHIVE_ACTION,
} from "../../configs/constants/invoiceConstants";
import useRightNav from "../../layouts/RightSideNav/useRightNav";
import { getInvoiceActivity, getInvoiceComments } from "../../redux/actions/InvoiceActions";
import { ENDPOINT_INVOICES, INVOICE_TYPE_CREDIT_NOTE } from "../../utils/api";
import {
  canAddInvoiceComment,
  canApproveDeveloperInvoice,
  canApproveInvoice,
  canArchiveInvoice,
  canDeleteDeveloperInvoice,
  canEditDeveloperInvoice,
  canEditInvoice,
  canUnarchiveInvoice,
  canViewInvoiceHistory,
  hasProjectAccess,
  isAdmin,
  isCM,
  isCMOrCSOAndHasProjectAcess,
  isInternalUser,
  isPayAdmin,
  isSpaceLead,
} from "../../utils/auth";
import { performAction, showAction } from "../../utils/invoiceUtils";
import { CustomSelector } from "../../utils/styles";
import Icon from "../Icon";
import InvoiceStatus from "../InvoiceStatus";
import InvoiceTrack from "../InvoiceTrack";
import Progress from "../Progress";
import CloseIcon from "../svg/CloseIcon";
import Comments from "./Comments";

const PAYOUT = "payout";
const PAYMENT = "payment";

const Tile = ({ left, renderRight, testId }) => {
  const comp = renderRight();

  return (
    <TileWrapper>
      <div className="tile">
        <div>{left}</div>
        <div data-testid={testId}>{comp}</div>
      </div>
      <hr />
    </TileWrapper>
  );
};

const InvoiceHistory = ({ selected_invoice, type }) => {
  const { close: closeSideBar } = useRightNav();
  const dispatch = useDispatch();

  const [isAction, setAction] = useState(false);
  const [selected, setSelected] = useState("");
  const { isMakingRequest, activity } = useSelector(({ Invoice }) => Invoice);
  const { project } = useSelector(({ Projects }) => Projects);

  const activelySelectedProject =
    project && Object.keys(project).length !== 0 ? project : selected_invoice?.project;
  const enableCommentSection = hasProjectAccess(activelySelectedProject) && canAddInvoiceComment();

  const handleChange = (e) => {
    const { value } = e.target;
    setSelected(value);
    performAction(value, selected_invoice);
  };

  const getOptions = () => {
    const approvePayoutObj = { id: APPROVE_BATCH_ACTION, name: "Approve Payout" };
    const approveCSOPayoutObj = { id: APPROVE_CSO_PAYOUT_ACTION, name: "Approve Draft" };
    const deletePayoutObj = { id: DELETE_ACTION_BATCH, name: "Delete" };
    const editPayoutObj = { id: EDIT_ACTION_BATCH, name: "Edit" };
    const markPayoutAsPaidObj = { id: MARK_AS_PAID_BATCH_ACTION, name: "Mark as Paid" };
    const rejectPayout = { id: REJECT_PAYOUT_ACTION, name: "Reject Draft" };
    const markPaymentAsPaidObj = { id: MARK_AS_PAID_ACTION, name: "Mark as paid" };
    const editPaymentObj = { id: EDIT_ACTION, name: "Edit" };
    const deletePaymentObj = { id: DELETE_ACTION_BATCH, name: "Delete" };
    const archiveActionObj = { id: ARCHIVE_ACTION, name: "Archive" };
    const sendReminder = { id: SEND_REMINDER, name: "Send Reminder" };
    const approvePaymentObj = { id: APPROVE_ACTION, name: "Approve" };
    const sendForApprovalObj = { id: SEND_FOR_APPROVAL_ACTION, name: "Send for approval" };
    const rejectActionObj = { id: REJECT_ACTION, name: "Reject" };
    const generateInvoiceObj = { id: GENERATE_INVOICE_ACTION, name: "Generate Invoice" };
    const unarchiveObj = { id: UNARCHIVE_ACTION, name: "Unarchive Invoice" };

    let ops = [];

    switch (type) {
      case PAYOUT: {
        let options = [];

        /* Checking if the selected invoice's payment platform is payoneer. */
        let paymentToPayoneer = selected_invoice?.payment_platform === "payoneer";

        /* Checking if the selected invoice payment platform is not payoneer or if the selected invoice payment platform is null. */
        let isNotPaymentToPayoneer =
          selected_invoice?.payment_platform !== "payoneer" ||
          selected_invoice?.payment_platform === null;

        /* Checking if the invoice user payment provider status is approved or active. */
        let paymentProviderActive =
          selected_invoice?.user?.payment_provider?.status === "approved" ||
          selected_invoice?.user?.payment_provider?.status === "active";

        /* The code is checking if a certain action, DELETE_ACTION_BATCH, should be shown for a
        selected invoice. If the condition is true, it adds the deletePayoutObj to an array called
        options. */
        if (showAction(DELETE_ACTION_BATCH, selected_invoice)) {
          options = [deletePayoutObj, ...options];
        }

        /* The code is checking if a specific action, EDIT_ACTION_BATCH, should be shown for a
        selected invoice. If the condition is true, it adds an editPayoutObj to an options array. */
        if (showAction(EDIT_ACTION_BATCH, selected_invoice)) {
          options = [editPayoutObj, ...options];
        }

        /* Checking if the payment provider is active or not. */
        if (selected_invoice?.invoices?.length > 0) {
          paymentProviderActive = !selected_invoice?.invoices?.some((invoiceItem) => {
            /* Checking if the user has a payment provider and if the status is not approved or active. */
            const check =
              invoiceItem?.user?.payment_provider?.status !== "approved" &&
              invoiceItem?.user?.payment_provider?.status !== "active";

            return check;
          });

          /* Checking if the selected invoice has a payment platform of payoneer. */
          paymentToPayoneer = selected_invoice?.invoices?.some((invoiceItem) => {
            const check = invoiceItem?.payment_platform === "payoneer";
            return check;
          });

          /* Checking if the selected invoice has a payment platform that is not payoneer. */
          isNotPaymentToPayoneer = selected_invoice?.invoices?.some((invoiceItem) => {
            const check =
              invoiceItem?.payment_platform !== "payoneer" ||
              invoiceItem?.payment_platform === null;

            return check;
          });
        }

        if (isInternalUser() && selected_invoice.status === "draft") {
          if (showAction(APPROVE_CSO_PAYOUT_ACTION, selected_invoice)) {
            options = [approveCSOPayoutObj, ...options];
          }
          if (showAction(REJECT_PAYOUT_ACTION, selected_invoice)) {
            options = [rejectPayout, ...options];
          }
        }

        if (isPayAdmin()) {
          options = [markPayoutAsPaidObj, ...options];
        }

        /* Checking if the user is a PayAdmin, SpaceLead, or CM, and if the paymentToPayoneer is true,
        and if the showAction is true, then it will add the approvePayoutObj to the options array. */
        if (
          (isPayAdmin() || isSpaceLead() || isCM()) &&
          paymentToPayoneer &&
          !isNotPaymentToPayoneer &&
          showAction(APPROVE_BATCH_ACTION, selected_invoice)
        ) {
          if (!paymentProviderActive) {
            approvePayoutObj.disabled = true;
          }
          options = [approvePayoutObj, ...options];
        }

        if (selected_invoice.archived && showAction(UNARCHIVE_ACTION, selected_invoice)) {
          options = [unarchiveObj];
        }

        ops = [...options];

        break;
      }
      case PAYMENT: {
        let options = [];
        if (showAction(MARK_AS_PAID_ACTION, selected_invoice)) {
          options = [markPaymentAsPaidObj, ...options];
        }

        if (showAction(APPROVE_ACTION, selected_invoice)) {
          options = [approvePaymentObj, ...options];
        }

        if (showAction(SEND_FOR_APPROVAL_ACTION, selected_invoice)) {
          options = [sendForApprovalObj, ...options];
        }

        if (showAction(REJECT_ACTION, selected_invoice)) {
          options = [rejectActionObj, ...options];
        }
        if (showAction(SEND_REMINDER, selected_invoice)) {
          options = [sendReminder, ...options];
        }

        if (showAction(EDIT_ACTION, selected_invoice)) {
          options = [editPaymentObj, ...options];
        }
        if (showAction(ARCHIVE_ACTION, selected_invoice)) {
          options = [archiveActionObj, ...options];
        }

        if (showAction(DELETE_ACTION, selected_invoice)) {
          options = [deletePaymentObj, ...options];
        }
        if (showAction(GENERATE_INVOICE_ACTION, selected_invoice)) {
          options = [generateInvoiceObj, ...options];
        }
        if (showAction(UNARCHIVE_ACTION, selected_invoice)) {
          options = [unarchiveObj, ...options];
        }

        ops = [...options];

        break;
      }
      default:
        break;
    }

    return ops;
  };

  useEffect(() => {
    if (selected_invoice?.id) {
      dispatch(getInvoiceActivity(selected_invoice.id));
      dispatch(getInvoiceComments(selected_invoice.id));
    }
  }, [selected_invoice]);

  /** Update actions config, whether should be accessed or not */
  useEffect(() => {
    if (selected_invoice.id) {
      const factor =
        type === PAYOUT
          ? (isAdmin() ||
              isCMOrCSOAndHasProjectAcess(project) ||
              (selected_invoice?.project && !selected_invoice?.project.archived)) &&
            !selected_invoice.paid &&
            selected_invoice.status !== "approved"
          : showAction(MARK_AS_PAID_ACTION, selected_invoice) ||
            showAction(ARCHIVE_ACTION, selected_invoice) ||
            showAction(EDIT_ACTION, selected_invoice) ||
            showAction(UNARCHIVE_ACTION, selected_invoice) ||
            showAction(DELETE_ACTION, selected_invoice) ||
            showAction(SEND_FOR_APPROVAL_ACTION, selected_invoice);

      setAction(factor);
    }
  }, [selected_invoice]);

  return (
    <InvoiceHistoryWrapper>
      <div className="d-flex justify-content-between mb-4">
        <div className="w-50">
          <h4 className="title">{selected_invoice.title}</h4>
          <p className="subtitle m-0">
            {selected_invoice.number &&
            (selected_invoice.paid ||
              selected_invoice.finalized ||
              selected_invoice.last_sent_at ||
              type === "payout")
              ? selected_invoice.number
              : "N/A"}
          </p>
        </div>

        {(canApproveDeveloperInvoice() ||
          canDeleteDeveloperInvoice() ||
          canEditDeveloperInvoice() ||
          canEditInvoice() ||
          canApproveInvoice() ||
          canUnarchiveInvoice() ||
          canArchiveInvoice()) && (
          <div className="d-flex w-50 justify-content-end">
            {isAction && (
              <div className="select-wrapper justify-content-end">
                <CustomSelector>
                  <select className="form-control select" value={selected} onChange={handleChange}>
                    <option key="option-placeholder" value="">
                      Action
                    </option>
                    {getOptions().map((x) => (
                      <option
                        disabled={!!x.disabled}
                        key={x.id}
                        value={x.id}
                        style={{ fontWeight: "bold" }}
                      >
                        {x.name}
                      </option>
                    ))}
                  </select>
                  <Icon name="rounded-keyboard-arrow-down" size="sm" />
                </CustomSelector>
              </div>
            )}
          </div>
        )}
        <button
          type="button"
          className="p-0 close-btn"
          data-testid="history-close-btn"
          onClick={() => {
            setSelected("");
            closeSideBar();
          }}
        >
          <CloseIcon />
        </button>
      </div>
      <div className="header">Details</div>
      <Tile
        testId="amount"
        left="Amount"
        renderRight={() => {
          const currency = selected_invoice?.currency === "USD" ? "$" : "€";

          if (selected_invoice.type !== "purchase") {
            return `${selected_invoice.type === INVOICE_TYPE_CREDIT_NOTE ? "-" : ""}${currency}${
              selected_invoice.subtotal
            }`;
          }
          return `${currency}${selected_invoice.amount}`;
        }}
      />
      <Tile left="Status" renderRight={() => <InvoiceStatus invoice={selected_invoice} />} />
      <Tile
        left="Due date"
        testId="due_date"
        renderRight={() => moment.utc(selected_invoice.due_at).format("MMM DD, YYYY")}
      />
      <Tile
        left="Project"
        testId="project_title"
        renderRight={() => selected_invoice.project.title}
      />
      <Tile
        testId="client"
        left="Client"
        renderRight={() =>
          selected_invoice.project.owner ? selected_invoice.project.owner.display_name : "N/A"
        }
      />
      {selected_invoice?.ref_invoice?.id && (
        <Tile
          testId="ref_invoice"
          left="Reference Invoice"
          renderRight={() => (
            <a
              className="line-bottom"
              href={`${ENDPOINT_INVOICES}${selected_invoice.ref_invoice.id}/download/?format=pdf`}
              target="_blank"
              rel="noreferrer"
            >
              {selected_invoice.ref_invoice?.number}
            </a>
          )}
        />
      )}
      {canViewInvoiceHistory() && (
        <>
          <div className="header">History</div>

          {isMakingRequest?.activity ? <Progress /> : <InvoiceTrack data={activity} />}
        </>
      )}

      {enableCommentSection && <Comments invoice={selected_invoice} />}
    </InvoiceHistoryWrapper>
  );
};

const TileWrapper = styled.div`
  .tile {
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
    color: #3e4857;
    font-weight: 500;
  }
`;

const InvoiceHistoryWrapper = styled.div`
  padding: 24px;

  button {
    line-height: unset;
    height: unset;

    &.close-btn {
      background: transparent;
      padding: 0 !important;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      margin-top: 8px;
      margin-left: 10px;
    }
  }

  .select {
    width: 100%;
    margin-left: 0;
    font-weight: 500;
    color: rgb(48, 55, 66);

    &-wrapper {
      margin-right: 0;
    }
  }

  .title {
    color: rgb(48, 55, 66);
    font-size: 1.5em;
  }

  .subtitle {
    color: rgb(80, 92, 110);
    font-size: 1.2em;
  }

  .title,
  .subtitle {
    font-weight: 500;
  }

  .header {
    text-transform: uppercase;
    color: #8f9bb3;
    margin-top: 1.8em;
    text-align: left;
  }
`;

Tile.propTypes = {
  left: PropTypes.string,
  renderRight: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  testId: PropTypes.string,
};

InvoiceHistory.propTypes = {
  type: PropTypes.string,
  selected_invoice: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    paid: PropTypes.bool,
    archived: PropTypes.bool,
    currency: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    subtotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    number: PropTypes.string,
    type: PropTypes.string,
    finalized: PropTypes.bool,
    last_sent_at: PropTypes.string,
    due_at: PropTypes.string,
    payment_platform: PropTypes.string,
    invoices: PropTypes.arrayOf(PropTypes.shape({})),
    ref_invoice: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
    project: PropTypes.shape({
      title: PropTypes.string,
      archived: PropTypes.bool,
      owner: PropTypes.shape({
        display_name: PropTypes.string,
      }),
    }),
    user: PropTypes.shape({
      payment_provider: PropTypes.shape({ status: PropTypes.string }),
    }),
  }),
};

export default InvoiceHistory;
