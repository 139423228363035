import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Checkbox from "../Checkbox";

const CheckboxLabel = ({ key, label, checked, disabled, onChange, onBlur, className, testId }) => {
  return (
    <Wrapper className={`mb-2 ${className}`} key={key}>
      <label className="font-14 d-flex align-items-center text-capitalize">
        <Checkbox
          className="me-2"
          data-testid={testId || `check-${label}`}
          name="clear-it"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
        />
        {label}
      </label>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

CheckboxLabel.propTypes = {
  key: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  testId: PropTypes.string,
};

export default CheckboxLabel;
