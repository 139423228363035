export const APPROVE_BATCH_ACTION = "APPROVE_BATCH_ACTION";
export const GENERATE_INVOICE_ACTION = "GENERATE_INVOICE_ACTION";
export const PAY_ACTION = "PAY_ACTION";
export const EDIT_ACTION = "EDIT_ACTION";
export const DELETE_ACTION = "DELETE_ACTION";
export const MARK_AS_PAID_ACTION = "MARK_AS_PAID_ACTION";
export const MARK_AS_PAID_BATCH_ACTION = "MARK_AS_PAID_BATCH_ACTION";
export const ARCHIVE_ACTION = "ARCHIVE_ACTION";
export const DELETE_ACTION_BATCH = "DELETE_ACTION_BATCH";
export const EDIT_ACTION_BATCH = "EDIT_ACTION_BATCH";
export const APPROVE_ACTION = "APPROVE_ACTION";
export const APPROVE_CSO_PAYOUT_ACTION = "APPROVE_CSO_PAYOUT_ACTION";
export const SEND_FOR_APPROVAL_ACTION = "SEND_FOR_APPROVAL_ACTION";
export const REJECT_ACTION = "REJECT_ACTION";
export const REJECT_PAYOUT_ACTION = "REJECT_PAYOUT_ACTION";
export const SEND_REMINDER = "SEND_REMINDER";
export const UNARCHIVE_ACTION = "UNARCHIVE_ACTION";

export const REGION_US = "US";
export const REGION_SEPA = "SEPA";
export const PAYMENT_PROVIDER_DOLLAR = "dollar-account";
export const PAYMENT_PROVIDER_IBAN = "iban";
export const PAYMENT_PROVIDER_PAYONEER = "payoneer";
