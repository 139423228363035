const CATEGORIES = {
  dashboard: "DASHBOARD",
  projects: "PROJECTS",
  talentPool: "TALENT POOL",
  payments: "PAYMENTS",
  payouts: "PAYOUTS",
  settings: "SETTINGS",
};

const EVENTS = {
  click: "click",
  setup_payoneer: "setup_payoneer",
  go_to_profile_settings: "go_to_profile_settings",
  click_upcoming_payment_for: "click_upcoming_payment_for",
  click_scheduled_update_for: "click_scheduled_update_for",
  close_notification: "close_notification",
  clear_all_recent_notification: "clear_all_recent_notification",
  click_active_project: "click_active_project",
  click_active_project_settings: "click_active_project_settings",
  click_create_new_opportunity_button: "click_create_new_opportunity_button",
  create_new_opportunity: "create_new_opportunity",
  view_project: "view_project",
  view_opportunity: "view_opportunity",
  accept_invite: "accept_invite",
  activate_project: "activate_project",
  log_in: "Log In",
  view_invoice_history: "View Invoice History",
  create_new_payment: "Create New Payment",
  click_send_for_approval_action: "Click send for approval action",
  click_approve_invoice_action: "Click approve invoice action",
  click_reject_invoice_action: "Click reject invoice action",
  click_edit_invoice_action: "Click edit invoice action",
  click_generate_invoice_action: "Click generate invoice action",
  click_delete_invoice_action: "Click delete invoice action",
  click_send_invoice_reminder_action: "Click send invoice reminder action",
  click_mark_invoice_as_paid_action: "Click mark invoice as paid action",
  click_archive_action: "Click archive action",
  click_unarchive_action: "Click unarchive action",
  click_overdue_filter: "Click filter by overdue ",
  click_pending_filter: "Click filter by pending",
  click_paid_filter: "Click filter by paid ",
  click_credit_note_filter: "Click filter by credit note ",
  click_commitment_filter: "Click filter by commitment",
  click_archived_filter: "Click filter by archived",
  click_all_filter: "Click filter by all",
  create_new_payout: "Create New Payout",
  click_approve_payout: "Click approve payout",
  filter_by_payment_platform: "Filter by payment platform",
  upload_iban: "Upload IBAN ",
  download_iban: "Download IBAN",
  download_csv: "Download CSV",
  filter_total_payout_summary: "Filter total payout summary",
  filter_total_payment_summary: "Filter total payment summary",
  create_new_project: "Create New Project",
  view_dev_profile: "View Dev Profile",
  resync_profile: "Resync Profile",
  click_remove_from_shortlist: "Click remove from shortlist",
  copy_shortlist_access: "Copy shortlist access",
  add_dev_to_shortlist: "Add dev to shortlist",
  archive_project: "Archive Project",
  unarchive_project: "Unarchive Project",
  add_timeline: "Add Timeline",
  update_timeline: "Update Timeline",
  add_new_document: "Add New Document",
  delete_document: "Delete Document",
  view_project_activity: "View Project Activity",
  filter_project_report: "Filter Project Report",
  submit_timesheet_hours: "Submit Timesheet Hours",
  approve_timesheet_hours: "Approve Timesheet Hours",
  reject_timesheet_hours: "Reject Timesheet Hours",
  update_dev_payment_structure: "Update Dev Payment Structure",
  add_new_team_member: "Add New Team Member",
  update_project_details: "Update Project Details",
  share_shortlist: "Share shortlist",
  update_personal_details: "Update Personal Details",
  add_admin_email_address: "Add Admin Email Address",
  change_password: "Change Password",
  change_email_address: "Change Email Address",
  deactivate_account: "Deactivate Account",
  update_newsletter_email_switch: "Update Newsletter Email Switch",
  update_event_email_switch: "Update Event Email Switch",
  update_task_survey_reminder_email_switch: "Update Task Survey Reminder Email Switch",
  update_new_developer_progress_reports_reminder_email_switch:
    "Update New Developer Progress Reports Reminder Email Switch",
  update_cookie_performance_functionality_switch:
    "Update Performance and Functionality Cookies Switch",
  update_cookie_analytics_customization_switch: "Update Analytics and Customization Cookies Switch",
  update_cookie_targeting_advertising_switch: "Update Targeting and Advertising Cookies Switch",
  view_privacy_policy: "View Privacy Policy",
  view_user_agreement: "View User Agreement",
  view_code_of_conduct: "View Code of Conduct",
  update_cookie_settings: "Update Cookie Settings",
  deactivate_user: "Deactivate User",
  reactivate_user: "Reactivate User",
  send_invite: "Send Invite",
  create_client: "Create Client",
  view_pending_invites: "View Pending Invites",
  update_bio: "Update Bio",
  copy_profile_link: "Copy Profile Link",
  update_skillsets: "Update Skillsets",
  update_projects: "Update Projects",
  update_education: "Update Education",
  update_work_experience: "Update Work Experience",
  add_comment: "Add Comment",
  add_payment_provider: "Add Payment Provider",
};

export { CATEGORIES, EVENTS };
