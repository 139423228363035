/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownToggle } from "reactstrap";

import { CATEGORIES, EVENTS } from "../../../analytics/events";
import { trackEvent } from "../../../analytics/segment";
import Avatar from "../../../components/Avatar";
import Icon from "../../../components/Icon";
import InvoiceHistory from "../../../components/InvoiceHistory";
import OverlayTooltip from "../../../components/OverlayTooltip";
import ReactTable from "../../../components/ReactTable";
/* -------------------------- Internal dependencies ------------------------- */
import SummaryPlaceholder from "../../../components/SummaryPlaceholder/SummaryPlaceholder";
import {
  APPROVE_BATCH_ACTION,
  APPROVE_CSO_PAYOUT_ACTION,
  DELETE_ACTION_BATCH,
  EDIT_ACTION_BATCH,
  MARK_AS_PAID_BATCH_ACTION,
  REJECT_PAYOUT_ACTION,
  UNARCHIVE_ACTION,
} from "../../../configs/constants/invoiceConstants";
import useDropdownPopper from "../../../hooks/useDropdownPopper";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import { ENDPOINT_INVOICES, INVOICE_TYPE_PURCHASE } from "../../../utils/api";
import {
  canApproveDeveloperInvoice,
  canCreateDeveloperInvoice,
  canDeleteDeveloperInvoice,
  canEditDeveloperInvoice,
  canMarkInvoiceAsPaid,
  canRejectDeveloperInvoice,
  canUnarchiveInvoice,
  isCMOrCSOAndHasProjectAcess,
  isDev,
  isInternalUser,
  isPayAdmin,
} from "../../../utils/auth";
import {
  batchInvoices,
  filterInvoices,
  getPayoutTableColumns,
  onCreateInvoice,
  payoutTableData,
  showAction,
} from "../../../utils/invoiceUtils";
import { generateUserInitials } from "../../../utils/stringUtils";
// import ActionItem from "./components/ActionItem";
import DropdownActionItem from "./components/DropdownActionItem";
/* --------------------------- Styles dependencies -------------------------- */
import { StyledButtonDropdown } from "./styles";
import PayoutFilters from "./components/PayoutFilters";
import { useInvoices } from "../../../context/InvoiceContext";
import { columnFilter, generateColumnsFromArray } from "../../../utils/helpers";

const Payouts = (props) => {
  const {
    data: invoices,
    project,
    setcreateAction,
    onLoadMore,
    count,
    currentPage,
    pageSize,
  } = props;

  const [checked, setChecked] = useState([]);

  const { open: openSideBar } = useRightNav();
  const { paymentColumns, payoutState, setPaymentColumns } = useInvoices();

  const {
    styles: sideBarPaymentStyles,
    attributes: sideBarPaymentAttributes,
    setPopperElement: sideBarPaymentSetPopperElement,
    setReferenceElement: sideBarPaymentSetReferenceElement,
    dropDownPortal: sideBarPaymentDropDownPortal,
  } = useDropdownPopper();

  const { errors, isMakingRequest } = useSelector(({ Invoice }) => Invoice);

  const payouts = filterInvoices(invoices, INVOICE_TYPE_PURCHASE);
  const batchPayouts = batchInvoices(payouts);

  const [open, setopen] = useState(null);

  const scope = project === undefined || project === null ? "sideBarPayment" : "project";
  const allIDs = invoices.filter(({ paid, status }) => !(paid || status === "approved"));

  const actions = useMemo(() => {
    const newActions = [];

    if (showAction(APPROVE_BATCH_ACTION, checked)) {
      newActions.push({
        id: "approve",
        name: "Approve payout",
        value: "approve",
      });
    }

    if (showAction(MARK_AS_PAID_BATCH_ACTION, checked)) {
      newActions.push({
        id: "mark_as_paid",
        name: "Mark as paid",
        value: "mark_as_paid",
      });

      newActions.push({
        id: "archive",
        name: "Archive invoice",
        value: "archive",
      });
    }

    if (showAction(DELETE_ACTION_BATCH, checked)) {
      newActions.push({
        id: "delete",
        name: "Delete invoice",
        value: "delete",
      });
    }

    return newActions;
  }, [checked]);

  const [options, setOptions] = useState(() => [...actions]);

  const checkForIbanWiseNil = (items) => {
    const ibanWiseNil = items.some(
      ({ payment_platform }) =>
        payment_platform === "iban" ||
        payment_platform === "dollar-account" ||
        payment_platform === null,
    );

    const checkForInactiveAccount = items.some(
      ({ user, payment_platform }) =>
        payment_platform === "payoneer" &&
        !(
          user.payment_provider?.status === "approved" || user.payment_provider?.status === "active"
        ),
    );

    const updatedOptions = actions.map((option) => {
      if (option.id === "approve") {
        return {
          ...option,
          disabled: ibanWiseNil || checkForInactiveAccount,
        };
      }
      return option;
    });

    setOptions(updatedOptions);
  };

  const checkAll = () => {
    return setChecked((v) => {
      if (v.length === allIDs.length) {
        return [];
      }
      return allIDs;
    });
  };

  const checkItem = (item) => {
    let newArr = [];
    const hasItem = checked.filter((a) => a.id === item.id).length > 0;

    if (!hasItem) {
      newArr = [...checked, item];
    } else {
      newArr = checked.filter((a) => a.id !== item.id);
    }
    checkForIbanWiseNil(newArr);
    setChecked(newArr);
  };

  const toggleAll = (state) => {
    const checkboxes = document.querySelectorAll("[name=check-it]");
    const checkedBoxMapped = Array.from(checkboxes)
      .map((checkbox) => {
        checkbox.checked = state;

        const found = payouts.find((fp) => fp.id === +checkbox.id);
        return found;
      })
      .filter(Boolean);

    if (state) {
      checkForIbanWiseNil(checkedBoxMapped);
      setChecked(checkedBoxMapped);
    } else {
      checkForIbanWiseNil([]);
      setChecked([]);
    }
  };

  const getPayoutTableData = useCallback(() => {
    if (scope === "sideBarPayment") {
      return payoutTableData(payouts, scope);
    }

    return payoutTableData(batchPayouts, scope);
  }, [scope, payouts]);

  const isChecked = checked.length !== 0 && checked.length === allIDs.length;

  const tableColumnsRaw = getPayoutTableColumns(
    payoutState.invoiceStatus[0],
    project,
    scope,
    isChecked,
    checkAll,
  );

  const columnFilterFn = columnFilter(paymentColumns);
  const tableColumns = tableColumnsRaw.filter(columnFilterFn);
  const tableColumnsArr = generateColumnsFromArray(tableColumns);

  useEffect(() => {
    setPaymentColumns(tableColumnsArr);
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length && errors?.createBatch) {
      onCreateInvoice(INVOICE_TYPE_PURCHASE, {
        ...props,
        errors: errors?.createBatch || null,
      });
    }
  }, [errors]);

  useEffect(() => {
    if (setcreateAction)
      setcreateAction({
        ...(!project ||
        ((canCreateDeveloperInvoice() || isCMOrCSOAndHasProjectAcess(project)) &&
          !project?.archived)
          ? {
              visibility: true,
              add: [
                {
                  title: "Payout",
                  action: () => {
                    onCreateInvoice(INVOICE_TYPE_PURCHASE, props);
                  },
                },
              ],
            }
          : { visibility: false }),
      });
  }, []);

  const handleInvoiceHistory = (invoice) => {
    trackEvent(EVENTS.view_invoice_history, {
      invoice_id: invoice.id,
      invoice_title: invoice.full_title,
      event_category: CATEGORIES.payouts,
    });
    openSideBar(<InvoiceHistory selected_invoice={invoice} type="payout" />, { width: "450px" });
  };

  const toggleAction = (invoiceId) => {
    setopen(open === invoiceId ? null : invoiceId);
  };

  const getTableDisplayValue = (cell) => {
    const invoice = cell?.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    /* Checking if the invoice payment platform is payoneer. */
    let paymentToPayoneer = invoice?.payment_platform === "payoneer";

    /* Checking if the invoice payment platform is not payoneer or if the invoice payment platform is null. */
    let isNotPaymentToPayoneer =
      invoice?.payment_platform !== "payoneer" || invoice?.payment_platform === null;

    /* Checking if the invoice user payment provider status is approved or active. */
    let paymentProviderActive =
      invoice?.user?.payment_provider?.status === "approved" ||
      invoice?.user?.payment_provider?.status === "active";

    /* Checking if the payment provider is active or not. */
    if (invoice?.invoices?.length > 0) {
      paymentProviderActive = !invoice?.invoices?.some((invoiceItem) => {
        /* Checking if the user has a payment provider and if the status is not approved or active. */
        const check =
          invoiceItem?.user?.payment_provider?.status !== "approved" &&
          invoiceItem?.user?.payment_provider?.status !== "active";

        return check;
      });

      /* Checking if the invoice has a payment platform of payoneer. */
      paymentToPayoneer = invoice?.invoices?.some((invoiceItem) => {
        const check = invoiceItem?.payment_platform === "payoneer";
        return check;
      });

      /* The below code is checking if the invoice is not a payment to payoneer. */
      isNotPaymentToPayoneer = invoice?.invoices?.some((invoiceItem) => {
        const check =
          invoiceItem?.payment_platform !== "payoneer" || invoiceItem?.payment_platform === null;

        return check;
      });
    }

    /* Checking if the invoice is not approved, not paid, payment to payoneer, not payment to payoneer, and payment provider is not active. */
    const inactivePayoneerAccountCheck =
      invoice?.status !== "approved" &&
      !invoice?.paid &&
      paymentToPayoneer &&
      !isNotPaymentToPayoneer &&
      !paymentProviderActive;

    switch (cell.column.id) {
      case "batch_action": {
        const isBoxChecked = checked?.findIndex((a) => a.id === invoice.id) !== -1;

        return (
          <td key={`batch_action${key}`}>
            {!(invoice.paid || invoice.status === "approved") && (
              <input
                type="checkbox"
                name="check-it"
                className="custom-checkbox"
                data-testid={`checkbox-${key}`}
                id={invoice.id}
                checked={isBoxChecked}
                onClick={() => checkItem(invoice)}
              />
            )}
          </td>
        );
      }

      case "client": {
        return (
          <td className="nowrap" key={`client${key}`}>
            {invoice.project && invoice.project.owner ? invoice.project.owner.display_name : "N/A"}
          </td>
        );
      }
      case "project": {
        return (
          <td className="nowrap" key={`project${key}`}>
            <Link className="line-bottom" to={`/projects/${invoice.project.id}/pay/payouts/`}>
              {invoice?.project?.title || "N/A"}
            </Link>
          </td>
        );
      }
      case "freelancer": {
        return (
          <td className="nowrap" key={`freelancer${key}`}>
            <div className="d-flex align-items-center">
              <Avatar
                image={invoice?.user?.avatar_url}
                initials={generateUserInitials(invoice?.user)}
                size="dash"
              />
              {`${invoice?.user?.display_name}`}
            </div>
          </td>
        );
      }
      case "platform": {
        return (
          <td className="nowrap" key={`platform${key}`}>
            {invoice?.payment_platform || "Nil"}
          </td>
        );
      }
      case "due_at": {
        return (
          <td key={`due_at${key}`} className="nowrap">
            {moment.utc(invoice?.due_at).format("DD MMM YYYY")}

            {new Date(`${invoice?.due_at}`) < new Date() &&
              !invoice?.paid &&
              invoice?.status !== "approved" && (
                <Icon className="danger" name="exclamation-triangle" />
              )}
          </td>
        );
      }
      case "created_at": {
        return (
          <td key={`created_at${key}`} className="nowrap">
            {moment.utc(invoice?.issued_at).format("DD MMM YYYY")}
          </td>
        );
      }
      case "title": {
        return (
          <td className="nowrap" key={`title${key}`}>
            <button
              type="button"
              className="btn-trans text-capitalize line-bottom"
              onClick={() => handleInvoiceHistory(invoice)}
            >
              {invoice?.title}
            </button>
            {inactivePayoneerAccountCheck && (
              <OverlayTooltip
                tooltipId="payoneer-tooltip"
                placement="top"
                overlay={
                  isDev()
                    ? "Your Payoneer account is inactive, and payment can't be processed. Kindly update your PSP"
                    : "Developer has inactive Payoneer account, Payout cannot be approved"
                }
              >
                <Icon name="warning" size="sm" className="text-warning" />
              </OverlayTooltip>
            )}
          </td>
        );
      }
      case "exact_invoice_url": {
        return (
          <td className="nowrap" key={`exact_invoice_url${key}`}>
            {invoice?.exact_invoice_url ? (
              <a
                className="line-bottom"
                href={`${invoice?.exact_invoice_url}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                View in Exact
              </a>
            ) : (
              <b>N/A</b>
            )}
          </td>
        );
      }
      case "user": {
        return (
          <td key={`user${key}`} className="nowrap">
            {invoice?.invoices.map((item) => {
              return <div key={item.id}>{item.user.display_name}</div>;
            })}
          </td>
        );
      }
      case "invoice": {
        return (
          <td key={`invoice${key}`} className="nowrap">
            {invoice?.invoices ? (
              invoice?.invoices?.map((item) => {
                return (
                  <div key={item.id}>
                    <a
                      className="line-bottom"
                      href={`${ENDPOINT_INVOICES}${item.id}/download/?format=pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.number}
                    </a>
                  </div>
                );
              })
            ) : (
              <div key={invoice.id}>
                <a
                  className="line-bottom"
                  href={`${ENDPOINT_INVOICES}${invoice.id}/download/?format=pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {invoice.number}
                </a>
              </div>
            )}
          </td>
        );
      }
      case "amount": {
        const currency = invoice.currency === "USD" ? "$" : "€";
        const amount = `${currency}${invoice?.amount}`;
        return (
          <>
            {scope === "sideBarPayment" ? (
              <td key={`amount${key}`} className="nowrap cta">
                {amount}
              </td>
            ) : (
              <td key={`amount${key}`} className="nowrap">
                {invoice?.invoices?.map((item) => {
                  const itemCurrency = item.currency === "USD" ? "$" : "€";
                  const itemAmount = `${itemCurrency}${item?.amount}`;

                  return (
                    <div key={item.id}>
                      {itemAmount}{" "}
                      {invoice.paid && !item.paid && item.status !== "approved" && (
                        <Icon className="danger" name="exclamation-triangle" />
                      )}
                    </div>
                  );
                })}

                {!isDev() && <div className="subtotal">{amount}</div>}
              </td>
            )}
          </>
        );
      }

      case "status": {
        const isInvoiceOverdue = new Date(`${invoice.due_at}`) < new Date();
        const isProcessing =
          invoice?.payment_platform === "payoneer" &&
          ((isInvoiceOverdue && !invoice.paid && invoice.status === "approved") ||
            (!invoice.paid && invoice.status === "approved"));

        return (
          <td key={`status${key}`} className="nowrap">
            {isProcessing ? (
              <span className="pending">Processing</span>
            ) : invoice.paid ? (
              <span className="completed">Paid</span>
            ) : isInvoiceOverdue && !invoice.paid ? (
              <span className="overdue">Overdue</span>
            ) : invoice.status === "draft" ? (
              <span className="pending">Draft</span>
            ) : invoice.status === "rejected" ? (
              <span className="overdue">Rejected</span>
            ) : (
              <span className="pending">Pending</span>
            )}
          </td>
        );
      }

      case "actions": {
        return (
          <td key={`actions${key}`} className="cta">
            {/* TODO internal user is a staff user, we may need a permission for this later */}
            {isInternalUser() &&
              invoice?.project &&
              !invoice?.project.archived &&
              !invoice?.paid &&
              invoice?.status !== "approved" && (
                <div className="cta-wrapper" data-testid={`cta-wrapper-${invoice.id}`}>
                  <StyledButtonDropdown
                    isOpen={open === invoice.id}
                    toggle={() => toggleAction(invoice.id)}
                  >
                    <DropdownToggle
                      className="btn-more d-flex align-items-center"
                      ref={sideBarPaymentSetReferenceElement}
                    >
                      <Icon name="dots-horizontal-small" />
                    </DropdownToggle>

                    {sideBarPaymentDropDownPortal(
                      <DropdownMenu
                        className="dropdown-menu"
                        ref={sideBarPaymentSetPopperElement}
                        style={sideBarPaymentStyles.popper}
                        {...sideBarPaymentAttributes.popper}
                      >
                        {isPayAdmin() && paymentToPayoneer && !isNotPaymentToPayoneer && (
                          <DropdownActionItem
                            action={APPROVE_BATCH_ACTION}
                            invoice={invoice}
                            disabled={!paymentProviderActive}
                          >
                            <Icon name="circle-check" />
                            &nbsp;&nbsp;&nbsp; Approve payout
                          </DropdownActionItem>
                        )}

                        {canMarkInvoiceAsPaid() && (
                          <DropdownActionItem action={MARK_AS_PAID_BATCH_ACTION} invoice={invoice}>
                            <Icon name="circle-check" />
                            &nbsp;&nbsp;&nbsp; Mark as Paid
                          </DropdownActionItem>
                        )}

                        {canEditDeveloperInvoice() && (
                          <DropdownActionItem
                            action={EDIT_ACTION_BATCH}
                            invoice={invoice}
                            project={project}
                          >
                            <Icon name="circle-edit-outline" />
                            &nbsp;&nbsp;&nbsp; Edit invoice
                          </DropdownActionItem>
                        )}
                        {canDeleteDeveloperInvoice() && (
                          <DropdownActionItem action={DELETE_ACTION_BATCH} invoice={invoice}>
                            <Icon name="delete-outline" />
                            &nbsp;&nbsp;&nbsp; Delete invoice
                          </DropdownActionItem>
                        )}

                        {canUnarchiveInvoice() && (
                          <DropdownActionItem
                            {...props}
                            action={UNARCHIVE_ACTION}
                            invoice={invoice}
                          >
                            <Icon name="archive-outline" />
                            &nbsp;&nbsp;&nbsp; Unarchive
                          </DropdownActionItem>
                        )}

                        {canRejectDeveloperInvoice() &&
                          !invoice?.project?.archived &&
                          !invoice?.paid && (
                            <DropdownActionItem action={REJECT_PAYOUT_ACTION} invoice={invoice}>
                              <Icon name="cancel" />
                              &nbsp;&nbsp;&nbsp; Reject Draft
                            </DropdownActionItem>
                          )}
                        {canApproveDeveloperInvoice() && invoice.status === "draft" && (
                          <DropdownActionItem action={APPROVE_CSO_PAYOUT_ACTION} invoice={invoice}>
                            <Icon name="circle-check" />
                            &nbsp;&nbsp;&nbsp; Approve draft
                          </DropdownActionItem>
                        )}
                      </DropdownMenu>,
                    )}
                  </StyledButtonDropdown>
                </div>
              )}
          </td>
        );
      }

      // case "actions": {
      //   return (
      //     <td key={`actions${key}`} className="cta">
      //       {isCMOrCSOAndHasProjectAcess(project) &&
      //         !invoice.paid &&
      //         invoice.status !== "approved" && (
      //           <div className="cta-wrapper">
      //             {canApproveDeveloperInvoice() && paymentToPayoneer && !invoice.paid && (
      //               <ActionItem
      //                 disabled={!paymentProviderActive}
      //                 className="line-bottom"
      //                 invoice={invoice}
      //               >
      //                 Approve
      //                 <Icon name="round-arrow-back-ios" className="mx-2" />
      //               </ActionItem>
      //             )}

      //             <StyledButtonDropdown
      //               isOpen={open === invoice.ref}
      //               toggle={() => toggleAction(invoice.ref)}
      //             >
      //               <DropdownToggle
      //                 className="btn-more d-flex align-items-center"
      //                 ref={setReferenceElement}
      //               >
      //                 <Icon name="dots-horizontal-small" />
      //               </DropdownToggle>
      //               {dropDownPortal(
      //                 <DropdownMenu
      //                   className="dropdown-menu"
      //                   ref={setPopperElement}
      //                   style={styles.popper}
      //                   {...attributes.popper}
      //                 >
      //                   {canMarkInvoiceAsPaid() && (
      //                     <DropdownActionItem action={MARK_AS_PAID_BATCH_ACTION} invoice={invoice}>
      //                       <Icon name="circle-check" />
      //                       &nbsp;&nbsp;&nbsp; Mark as Paid
      //                     </DropdownActionItem>
      //                   )}
      //                   {canApproveDeveloperInvoice() && (
      //                     <DropdownActionItem action={REJECT_PAYOUT_ACTION} invoice={invoice}>
      //                       <Icon name="cancel" />
      //                       &nbsp;&nbsp;&nbsp; Reject Draft
      //                     </DropdownActionItem>
      //                   )}

      //                   {paymentToPayoneer && (
      //                     <DropdownActionItem
      //                       disabled={!paymentProviderActive}
      //                       action={
      //                         canApproveDeveloperInvoice() && invoice.status === "draft"
      //                           ? APPROVE_CSO_PAYOUT_ACTION
      //                           : canApproveDeveloperInvoice()
      //                           ? APPROVE_BATCH_ACTION
      //                           : ""
      //                       }
      //                       invoice={invoice}
      //                     >
      //                       <Icon name="circle-check" />
      //                       &nbsp;&nbsp;&nbsp; Approve payout
      //                     </DropdownActionItem>
      //                   )}

      //                   {canEditDeveloperInvoice() && (
      //                     <DropdownActionItem
      //                       action={EDIT_ACTION_BATCH}
      //                       invoice={invoice}
      //                       project={project}
      //                     >
      //                       <Icon name="circle-edit-outline" />
      //                       &nbsp;&nbsp;&nbsp; Edit
      //                     </DropdownActionItem>
      //                   )}

      //                   {canUnarchiveInvoice() && (
      //                     <DropdownActionItem
      //                       {...props}
      //                       action={UNARCHIVE_ACTION}
      //                       invoice={invoice}
      //                     >
      //                       <Icon name="archive-outline" />
      //                       &nbsp;&nbsp;&nbsp; Unarchive
      //                     </DropdownActionItem>
      //                   )}

      //                   {canEditDeveloperInvoice() && (
      //                     <DropdownActionItem action={DELETE_ACTION_BATCH} invoice={invoice}>
      //                       <Icon name="delete-outline" />
      //                       &nbsp;&nbsp;&nbsp; Delete
      //                     </DropdownActionItem>
      //                   )}
      //                 </DropdownMenu>,
      //               )}
      //             </StyledButtonDropdown>
      //           </div>
      //         )}
      //     </td>
      //   );
      // }

      default:
        return null;
    }
  };

  return (
    <div className="section mt-3">
      <PayoutFilters checked={checked} toggleAll={toggleAll} project={project} options={options} />

      {!isMakingRequest?.list && invoices.length === 0 ? (
        <SummaryPlaceholder
          className="page-filters-pay-summary"
          description="No payouts have been created yet"
        />
      ) : (
        <ReactTable
          scope="payout"
          colDeps={[JSON.stringify(checked), JSON.stringify(paymentColumns)]}
          tableData={getPayoutTableData()}
          selectedRows={checked}
          tableColumns={tableColumns}
          currentPage={currentPage}
          count={count}
          getTableDisplayValue={getTableDisplayValue}
          loadPage={onLoadMore}
          pageSize={pageSize}
          isLoading={isMakingRequest?.list}
          noDataMessage="No payouts have been created yet"
        />
      )}
    </div>
  );
};

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  archived: PropTypes.bool,
  filter: PropTypes.string,
  getList: PropTypes.func,
  project: PropTypes.shape({
    id: PropTypes.number,
    archived: PropTypes.bool,
  }),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  setcreateAction: PropTypes.func,
  onLoadMore: PropTypes.func,
  count: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
};

Payouts.propTypes = proptypes;

export default Payouts;
