import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonDropdown } from "reactstrap";
import moment from "moment";
import PropTypes from "prop-types";

import Toggle from "./Toggle";
import Menu from "./Menu";
import FilterHead from "./FilterHead";
import DateTimePicker from "../DateTimePicker";
import FieldError from "../FieldError";
import { useInvoices } from "../../context/InvoiceContext";

const DateFilter = ({ paymentType = "in" }) => {
  const isPayments = paymentType === "in";
  const { paymentState, payoutState, updatePaymentState, updatePayoutState } = useInvoices();

  const { date: invoiceDate } = isPayments ? paymentState : payoutState;

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [dropdownOpen, setDropdown] = useState(false);
  const [error, setError] = useState("");

  const toggle = () => {
    setError("");
    setDropdown(!dropdownOpen);
  };

  const onDoneClick = () => {
    if (!fromDate || !toDate || fromDate === "Invalid date" || toDate === "Invalid date") {
      setError("Both date fields are required.");
      return;
    }

    if (new Date(fromDate) > new Date(toDate)) {
      setError("Start date is greater than end date");
      return;
    }

    if (isPayments) {
      updatePaymentState("date", { from: fromDate, to: toDate });
    } else {
      updatePayoutState("date", { from: fromDate, to: toDate });
    }
    toggle();
  };

  const getLastDayOfMonth = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  };

  useEffect(() => {
    setFromDate(invoiceDate.from);
    setToDate(invoiceDate.to);
  }, [invoiceDate]);

  return (
    <Wrapper className="">
      <ButtonDropdown className="add-btn" isOpen={dropdownOpen} toggle={toggle}>
        <Toggle label="Date" />

        <Menu label="Date" minwidth="266px" onClick={onDoneClick}>
          <FilterHead label="Select date range" />
          <div className="date-pickers">
            <DateTimePicker
              id="start-date"
              className="tg-date-field from-date"
              placeholder="From"
              format="DD MMM YYYY"
              calendar={true}
              time={false}
              value={fromDate ? new Date(fromDate) : null}
              onChange={(date) => {
                setError("");
                setFromDate(moment(date).format());
              }}
              required
            />
          </div>
          <div className="date-pickers">
            <DateTimePicker
              id="end-date"
              className="tg-date-field"
              placeholder="To"
              format="DD MMM YYYY"
              calendar={true}
              time={false}
              max={getLastDayOfMonth()}
              value={toDate ? new Date(toDate) : null}
              onChange={(date) => {
                setError("");
                setToDate(moment(date).format());
              }}
              required
            />
            {error && <FieldError className="error-msg text-sm mt-1" message={error} />}
          </div>
        </Menu>
      </ButtonDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .cursor-pointer {
    cursor: pointer;
  }

  .date-pickers {
    padding: 0 17px;

    .rw-calendar {
      margin: 0;
    }

    .rw-widget {
      border: 1px solid rgba(194, 204, 217, 0.25);
      box-shadow: none;

      input {
        background-color: #fff;
        box-shadow: none;
        color: #8f9bb3;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        ::placeholder {
          color: #8f9bb3;
        }
      }
    }

    .from-date {
      margin-bottom: 10px;
    }

    .error-msg {
      font-size: 12px;
    }
  }
`;

DateFilter.propTypes = {
  paymentType: PropTypes.string,
};

export default DateFilter;
